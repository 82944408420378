#document-page{
    background-color: white;
    box-shadow: 0 0 2rem 0 black;
    margin:auto;
    max-width: 1200px;
}

.card-document{
    box-shadow: -1px 1px 8px 0px rgba(62,150,199,0.27);
    border: solid 1px rgb(73, 158, 204);
}

.info-title{
    background-color: rgb(173, 225, 254);
    border-radius: 0.4em;
    padding: 0.2em 0em 0em 0.8em;
}

.bg-total{
    background-color: rgb(173, 225, 254);
    border-radius: 0.4em;
}

.radius-pot{
    border-radius: 0.4em;
}

.bg-potencia{
    background-color: rgb(227, 229, 255); 
}
.bg-energia{
    // background-color: rgb(227, 245, 255);  
    background-color: rgb(173, 225, 254);
}
.bg-units{
    border: 1px solid lightgrey;
    border-radius: 0.4em;
}

.bg-ahorro{
    background-color: rgb(202, 255, 217);
}

.document-total{
    display:flex;
    justify-content: space-between;
}

.list-item-documento {
    padding:0.2rem;
}

.card-billing-documento .panel-data-resolve
{
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.card-billing-documento .panel-data-resolve .item-data
{
  display:flex;
  justify-content: space-between;
  height: 50px;
}


